import React from 'react';
import size from 'lodash/size';
import values from 'lodash/values';
import classNames from 'classnames';
import { CURRENCY } from 'CONFIG';
import { DEFAULT_PRICE_TYPE, LOT_ACTION_TYPE } from 'CONST';
import OldPrice from 'COMPONENTS/classified/oldPrice';
import { checkNested } from 'MODULES/checkNested';
import { getActionClassName } from 'MODULES/getActionClassName';

const PriceFormatter = ({ hideIcons, action, defaultReturnValue, prices, selectedPriceType, useWrapper, showFirst, hideCurrency, showFull, i18n, hideVat }) => {
    const actionId = checkNested(action, 'id');
    const priceValueClass = classNames('pp-price-value', {
        'is-single': values(prices).length === 1,
        'mt-4 mt-lg-0': checkNested(action, 'id') !== LOT_ACTION_TYPE.sell.id && size(prices) > 0
    });

    if (prices && prices[0]) {
        return prices.map((price, index) => {
            if (showFirst && index > 0) {
                return null;
            }
            const value = [];
            if (!price.value || (selectedPriceType && selectedPriceType !== price.priceType.id)) return defaultReturnValue;

            value.push(price.discountedValue
                ? price.discountedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '  ').replace('.00', '')
                : price.value.replace(/\B(?=(\d{3})+(?!\d))/g, '  ').replace('.00', ''));
            if (!hideCurrency) {
                value.push(CURRENCY);
            }

            if (price.priceType && price.priceType.id !== DEFAULT_PRICE_TYPE) {
                const longPrice = checkNested(price.priceType, 'name', '', true);
                const shortPrice = checkNested(price.priceType, 'short', '', true);
                if (longPrice || shortPrice) {
                    value.push(<span
                        key={longPrice.toLowerCase()}
                    >
                        {showFull ? longPrice.toLowerCase() : shortPrice.toLowerCase()}
                    </span>);
                }
            }
            if (index > 0) {
                return <span className="pp-rent-value" key={value}>{value}</span>;
            }
            if (useWrapper) {
                return (
                    <div className="grid-row-14" key={value}>
                        <h2 className={priceValueClass} key={value}>
                            {hideIcons && actionId && <i className={getActionClassName(actionId)} />}
                            {checkNested(action, 'id') !== LOT_ACTION_TYPE.sell.id && size(prices) > 0 && (
                                <span className="type">
                                    {checkNested(action, 'name')}
                                </span>
                            )}
                            <div className="text-nowrap">{value}</div>
                            {size(prices) > 0 && !hideVat && (
                                <div className="ms-auto vat-included">
                                    <span className="me-1 fs-1rem">%</span>
                                    <span>{i18n.__('VAT included')}</span>
                                </div>
                            )}
                        </h2>
                    </div>
                );
            }
            if (price.priceType) {
                value.push(<OldPrice key="lastPriceInHistory" price={price} prices={prices} priceTypeId={price.priceType.id} discountedValue={checkNested(prices, '[0].discountedValue')} />);
            }
            return value;
        });
    }
    return (
        <div className="action-block">
            {actionId && !hideIcons && (
                <h6>
                    <i className={getActionClassName(actionId)} />
                    {action && action.name}
                </h6>
            )}
        </div>
    );
};

export default PriceFormatter;
