import React, { Suspense, useEffect, useState, useRef } from 'react';

import classNames from 'classnames';
import { isIE, isMobile } from 'react-device-detect';
import { STORAGE } from 'CONFIG';
import { history } from 'STORE';
import size from 'lodash/size';

import ScrollToTop from 'COMMON/scroll_to_top';
import Footer from 'COMPONENTS/footer';
import Header from 'CONTAINERS/main_header';
import { CropperLoader } from 'COMMON/loader';

import { lazyRetry, loadComponent } from 'MODULES/lazyRetry';
import { useSelector } from 'react-redux';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import Translator from 'gettext-translator';
import Storage from 'HOC/storage';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import HomeMobileWrapper from 'CONTAINERS/HomeMobileWrapper';
import { checkNested } from 'MODULES/checkNested';
import { PP_URLS } from 'CONFIG/PP_URLS';

const process = { env: __DOTENV__ || window.env };

const Notifications = loadComponent(() => lazyRetry(() => import('CONTAINERS/notifications')));

const Offline = loadComponent(() => lazyRetry(() => import('COMPONENTS/api_offline')));

const MenuRight = loadComponent(() => lazyRetry(() => import('CONTAINERS/menu_right')));

const DataComponent = loadComponent(() => lazyRetry(() => import('COMPONENTS/hoc/data')));

const Feeds = loadComponent(() => lazyRetry(() => import('CONTAINERS/feeds')));
const UserClassifieds = loadComponent(() => lazyRetry(() => import('CONTAINERS/user_classifieds')));

const Modal = loadComponent(() => lazyRetry(() => import('CONTAINERS/modals')));

const AuthModals = loadComponent(() => lazyRetry(() => import('CONTAINERS/auth_modals')));

const Referral = loadComponent(() => lazyRetry(() => import('CONTAINERS/referral')));

const ModalMap = loadComponent(() => lazyRetry(() => import('CONTAINERS/modal_map')));

const ParentCategory = loadComponent(() => lazyRetry(() => import('CONTAINERS/parent_category')));
const PP = loadComponent(() => lazyRetry(() => import('CONTAINERS/pp')));
const PublishClassified = loadComponent(() => lazyRetry(() => import('CONTAINERS/publish_classified')));
const Bookmarks = loadComponent(() => lazyRetry(() => import('CONTAINERS/bookmarks')));
const Items = loadComponent(() => lazyRetry(() => import('CONTAINERS/items')));
const MYPARSE = loadComponent(() => lazyRetry(() => import('CONTAINERS/my_parse')));
const Profile = loadComponent(() => lazyRetry(() => import('CONTAINERS/profile')));
const MyCompany = loadComponent(() => lazyRetry(() => import('CONTAINERS/my_company')));
const Company = loadComponent(() => lazyRetry(() => import('CONTAINERS/company_public')));
const CreateClassified = loadComponent(() => lazyRetry(() => import('CONTAINERS/create_wrapper')));
const XMLDocs = loadComponent(() => lazyRetry(() => import('CONTAINERS/xml_doc')));
const StaticPage = loadComponent(() => lazyRetry(() => import('CONTAINERS/static_page')));
const Subscription = loadComponent(() => lazyRetry(() => import('./containers/subscription')));
const StylePage = loadComponent(() => lazyRetry(() => import('./components/style_page')));
const StyleLogo = loadComponent(() => lazyRetry(() => import('./components/style_logo')));
const Search = loadComponent(() => lazyRetry(() => import('./containers/search')));
const Filters = loadComponent(() => lazyRetry(() => import('./components/filters')));
const Social = loadComponent(() => lazyRetry(() => import('./components/social')));
const Messaging = loadComponent(() => lazyRetry(() => import('./containers/messaging')));
const QRUpload = loadComponent(() => lazyRetry(() => import('./containers/qr_upload')));
const Impersonate = loadComponent(() => lazyRetry(() => import('./containers/impersonate')));
const Digest = loadComponent(() => lazyRetry(() => import('./containers/digest')));

const HomeDesktop = loadComponent(() => lazyRetry(() => import('CONTAINERS/home')));
const PpCompare = loadComponent(() => lazyRetry(() => import('CONTAINERS/pp_compare')));



const Home = isMobile ? HomeMobileWrapper : HomeDesktop;







const App = ({ ...props }) => {
    const lang = useSelector(getSelectedLanguage);
    const [languageState, setLanguageState] = useState({});
    const [init, setInit] = useState({});
    const langRef = useRef(lang);

    useEffect(() => {
        if (size(init) > 0) {
            const i18n = {
                __: i18nTranslate,
                p__: (context, text, replacable = null) => (createTranslation(text, lang, replacable, true, context)),
                pluralTranslation,
                dynamicTranslations
            };
            const i18nLang = { [lang]: i18n };
            setLanguageState({ i18nLang });
        }
    }, [init]);


    useEffect(async () => {
        if (langRef.current !== lang) {
            langRef.current = lang;
            const path = window.location.hostname.indexOf('pp.lv') === -1
                ? `${process.env.API_URL_PUB}/i18n/${lang}.json`
                : `https://api.pp.lv/i18n/${lang}.json`;

            try {
                const response = await fetch(path);
                try {
                    const responseJson = await response.json();
                    try {
                        initializeTranslations(responseJson);
                    } catch (e) {
                        throw new Error(checkNested(e, 'message', 'Unable to initialize translations'));
                    }
                } catch (e) {
                    throw new Error(checkNested(e, 'message', 'Unable to convert language file'));
                }
            } catch (e) {
                throw new Error(e);
            }
        }
    }, [lang]);


    const translate = (language, jsons) => new Translator(jsons[language]);

    const createTranslation = (text, language, replacable, contextual = false, context = null) => {
        try {
            const translateObj = checkNested(init, [lang], {});
            if (contextual) {
                if (replacable) {
                    return translateObj.p__(context, text, replacable);
                }

                return translateObj.p__(context, text);
            }

            if (replacable && text.indexOf('% ') === -1) {
                return translateObj.__(text, replacable);
            }

            return translateObj.__(text);
        } catch (e) {
            return text;
        }
    };

    const pluralTranslation = (items, translations) => {
        if (items >= 5) return translations[5];
        if (items > 1 && items < 5) return translations[2];
        if (items === 1) return translations[1];
        return translations[0];
    };

    const i18nTranslate = (text, replacable = null) => createTranslation(text, lang || Storage.get(STORAGE.language), replacable);

    const dynamicTranslations = (text, replaceable = null) => {
        if (!text) {
            return '';
        }
        return i18nTranslate(text, replaceable);
    };

    const initializeTranslations = (lngFile = {}) => {
        const init = { [lang]: translate(lang, { [lang]: lngFile }) };

        setInit(init);
    };

    useEffect(async () => {
        const path = window.location.hostname.indexOf('pp.lv') === -1
            ? `${process.env.API_URL_PUB}/i18n/${lang || 'lv'}.json`
            : `https://api.pp.lv/i18n/${lang || 'lv'}.json`;

        try {
            const response = await fetch(path);
            try {
                const responseJson = await response.json();
                try {
                    initializeTranslations(responseJson);
                } catch (e) {
                    throw new Error(checkNested(e, 'message', 'Unable to initialize translations'));
                }
            } catch (e) {
                throw new Error(checkNested(e, 'message', 'Unable to convert JSON'));
            }
        } catch (e) {
            throw new Error(e);
        }
    }, []);

    const { i18nLang } = languageState;
    const i18n = i18nLang && i18nLang[lang || 'lv'];
    if (!i18n || !i18n.__) {
        return null;
    }

    const preloader = document.querySelector('#ppPreloader');
    if (preloader) {
        preloader.parentNode.removeChild(preloader);
    }

    return (
        <BrowserRouter history={history}>


            <Suspense fallback={null}><DataComponent key="data_component" formConfig={props.formConfig} i18n={i18n} /></Suspense>
            {i18n && <Suspense fallback={null}><AuthModals i18n={i18n} key="modals_for_authorization" /></Suspense> }
            {i18n && <Suspense fallback={null}><Modal key="modals" i18n={i18n} /></Suspense>}

            <Suspense fallback={null}><Notifications /></Suspense>
            <Suspense fallback={null}><Offline key="offline" i18n={i18n} /></Suspense>
            {i18n && <Suspense fallback={null}><Header key="mainHeader" i18n={i18n} /></Suspense>}
            {i18n && <Suspense fallback={null}><MenuRight key="menuRight" i18n={i18n} lang={lang} /></Suspense>}
            <div id="g_id_onload" />
            <div id="i_id_onload" />
            <div key="main_wrapper" className={classNames('main-wrapper', { isIE })}>
                <Suspense fallback={CropperLoader()}>
                    <Switch>
                        <Route exact path={PP_URLS.REFERRAL(':user', ':hash', ':lang')} render={p => <Referral {...p} i18n={i18n} />} />

                        <Route
                            exact
                            path={PP_URLS.HOME}
                            render={p => (
                                <div style={{ minHeight: isMobile ? undefined : '100vh' }}>
                                    {' '}
                                    <Suspense fallback={CropperLoader()}><Home {...p} i18n={i18n} /></Suspense>
                                </div>
                            )}
                        />
                        <Route
                            exact
                            path="/:lang"
                            render={p => (
                                <div style={{ minHeight: isMobile ? undefined : '100vh' }}>
                                    {' '}
                                    <Suspense fallback={CropperLoader()}><Home {...p} i18n={i18n} /></Suspense>
                                </div>
                            )}
                        />

                        <Route exact path={PP_URLS.USER_CLASSIFIEDS(':user', ':lang')} render={p => <UserClassifieds {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.XML_DOCS(':lang')} render={p => <XMLDocs {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.ABOUT('*', ':lang')} render={p => <StaticPage {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.CONTACTS(':lang')} render={p => <StaticPage {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.COOPERATIVE(':lang')} render={p => <StaticPage {...p} i18n={i18n} />} />
                        <Route exact path="/:lang/landing/*/!:region" render={p => <Home {...p} i18n={i18n} />} />
                        <Route exact path="/:lang/landing/*" render={p => <Home {...p} i18n={i18n} />} />
                        <Route exact path="/:lang/landing/:categorySlug" render={p => <Home {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.PP(':ppid', '**', ':lang')} render={p => <PP {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.MY_PARSE} render={p => <MYPARSE {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.BOOKMARKS} render={p => <Bookmarks {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.ITEMS} render={p => <Items {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.COMPANY} render={p => <MyCompany {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.PROFILE} render={p => <Profile {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.COMPANY_PUBLIC(':id', ':lang')} render={p => <Company {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.CREATE} render={p => <CreateClassified {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.EDIT(':id')} render={p => <CreateClassified {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.PUBLISH(':id')} render={p => <PublishClassified {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SUBSCRIPTION_STEP_PLAN(':step', ':plan')} render={p => <Subscription {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SUBSCRIPTION_STEP_ITEM(':step', ':lot')} render={p => <Subscription {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SUBSCRIPTION_STEP(':step')} render={p => <Subscription {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SUBSCRIPTION_STEP(':step')} render={p => <Subscription {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SUBSCRIPTION_PLANS(':lang')} render={p => <Subscription {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.STYLE_PAGE} render={p => <StylePage {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.STYLE_LOGO} render={p => <StyleLogo {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SEARCH_QUERY(':searchQuery', ':lang')} render={p => <Search {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SEARCH(':lang')} render={p => <Search {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.FILTERS_BY_HASH(':hash')} render={p => <Filters {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.FILTERS} render={p => <Filters {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.SOCIAL} render={p => <Social {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.MESSAGES_BY_HASH(':hash')} render={p => <Messaging {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.MESSAGES} render={p => <Messaging {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.FEED} render={p => <Feeds {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.MYALL} render={() => <Redirect to={PP_URLS.PROFILE} />} />
                        <Route exact path={PP_URLS.DIGEST(':lang', ':hash')} render={p => <Digest {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.PICTURE_UPLOAD_QR(':hash', ':lot', ':lang')} render={p => <QRUpload {...p} showPpError i18n={i18n} />} />
                        <Route exact path={PP_URLS.IMPERSONATE(':lang')} render={p => <Impersonate {...p} i18n={i18n} />} />
                        <Route exact path={PP_URLS.COMPARE_LOTS(':lang')} render={p => <PpCompare {...p} i18n={i18n} />} />

                        <Route path={PP_URLS.MODAL_MAP('*', ':lang')} render={p => <ModalMap {...p} i18n={i18n} />} />

                        <Route path={PP_URLS.CATEGORY('*', ':lang')} render={p => <ParentCategory {...p} i18n={i18n} />} />

                    </Switch>
                </Suspense>
                <div className="action-buttons-container d-flex flex-column">
                    <ScrollToTop />
                </div>
            </div>

            <Suspense fallback={CropperLoader()}>
                {i18n && <Footer key="footer" i18n={i18n} />}
            </Suspense>
        </BrowserRouter>


    );
};

export default App;
