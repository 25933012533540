import {
    GET_PAYMENT_METHODS,
    CLEAR_PAYMENTS_STATE,
    GET_PAYMENT_STATUS,
    SUBSCRIPTION_TEMPLATES, SET_USER_CONTACTS_FEE, SET_TOLL_CATEGORY_FEE
} from 'ACTIONS';
import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = {
    paymentMethods: {},
    paymentStatus: {},
    transactionId: null,
    templates: null,
    userContactsFee: null,
    tollCategoryFee: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PAYMENT_METHODS:
            return { ...state, paymentMethods: checkNested(action.payload, ['content', 'methods'], action.payload.methods), transactionId: action.payload.transactionId };
        case CLEAR_PAYMENTS_STATE:
            return { ...INITIAL_STATE, templates: state.templates };
        case GET_PAYMENT_STATUS:
            return { ...state, paymentStatus: action.payload };
        case SUBSCRIPTION_TEMPLATES:
            return { ...state, templates: action.payload };
        case SET_USER_CONTACTS_FEE:
            return { ...state, userContactsFee: Number(action.ppServiceFee) };
        case SET_TOLL_CATEGORY_FEE:
            return { ...state, tollCategoryFee: action.tollCategoryFee };
        default:
            return state;
    }
}
